// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-candidatura-js": () => import("./../../../src/pages/candidatura.js" /* webpackChunkName: "component---src-pages-candidatura-js" */),
  "component---src-pages-cursos-js": () => import("./../../../src/pages/cursos.js" /* webpackChunkName: "component---src-pages-cursos-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-perguntas-frequentes-js": () => import("./../../../src/pages/perguntas-frequentes.js" /* webpackChunkName: "component---src-pages-perguntas-frequentes-js" */),
  "component---src-pages-politica-de-privacidade-js": () => import("./../../../src/pages/politica-de-privacidade.js" /* webpackChunkName: "component---src-pages-politica-de-privacidade-js" */),
  "component---src-pages-sobre-nos-js": () => import("./../../../src/pages/sobre-nos.js" /* webpackChunkName: "component---src-pages-sobre-nos-js" */),
  "component---src-pages-termos-e-condicoes-js": () => import("./../../../src/pages/termos-e-condicoes.js" /* webpackChunkName: "component---src-pages-termos-e-condicoes-js" */),
  "component---src-templates-curso-candidatura-jsx": () => import("./../../../src/templates/curso-candidatura.jsx" /* webpackChunkName: "component---src-templates-curso-candidatura-jsx" */),
  "component---src-templates-curso-detalhes-jsx": () => import("./../../../src/templates/curso-detalhes.jsx" /* webpackChunkName: "component---src-templates-curso-detalhes-jsx" */)
}

